* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lato';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #FAFAFB;
}


.ql-toolbar.ql-snow {
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}

.ql-container.ql-snow {
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  background-color: #F4F6F8;
}

.squircle {
  mask-image: paint(smooth-corners);
  -webkit-mask-image: paint(smooth-corners);
  background: #d01257;
}

.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.demo-app-sidebar-section {
  padding: 2em;
}

.demo-app-main {
  flex-grow: 1;
  padding: 3em;
}

.fc {
  /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}